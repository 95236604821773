<template>
  <van-popup v-model:show="show" @opened="handleOpen">
    <div class="flex-section">
      <div class="table-responsive">
        <div class="chatbox">
          <div id="flex" ref="flexRef"></div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { nextTick, onMounted, ref, watch } from 'vue'

import { genCard1 } from '@/utils/card2'

export default {
  name: 'FlexView',
  props: ['content'],
  setup(props) {
    const content = props.content
    const show = props.show
    const flexRef = ref(null)
    const flexContent = ref(null)

    console.log('create')

    onMounted(() => {
      flexContent.value = genCard1(content)

      // flexRef.value.innerHTML = ''
      // flex2html("flex", flexContent)
      // console.log('ct1', flexContent)
    })

    // watch(() => props.content, (newVal, oldVal) => {
    //   flexContent = genCard1(JSON.parse(newVal))
    //   flexRef.value.innerHTML = ''
    //   flex2html("flex", state.value.flexContent)
    // })

    function handleOpen() {
      nextTick(() => {
        console.log('flex', flexRef.value)

        flexRef.value.innerHTML = ''
        flex2html("flex", flexContent.value)
      })
    }

    return {
      show,
      handleOpen
    }
  }
}
</script>

<style lang="less" scoped>
.flex-section {}

.chatbox {
  background-color: #666;
  margin-top: 10px;
  padding-top: 10px;
}

.flex-section {
  color: white;
  // z-index: 99999;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
}
</style>